.phaseBtn {
  position: relative;
  color: white !important;
  border-radius: 0;
  min-width: 100%;
}

.innerNav {
  flex-wrap: wrap;
  box-shadow: 0.1em 0.1em 0.1em #545454;
}

.innerNav li {
  flex-grow: 1;
}

@media screen and (min-width: 768px) {
  .innerNav {
    flex-wrap: nowrap;
  }
}
