.basketCart {
  opacity: 0;
  display: none;
  position: absolute;
  width: 320px;
  text-align: left;
  right: 0;
  z-index: 1000;
}

.basket {
  position: fixed;
  right: 2.3%;

  top: 1rem;
  line-height: 1.2;
  font-size: 21px;
  text-align: left;
  z-index: 1000;
  height: 65px;
}

.basket i {
  font-size: 2rem;
  color: #a61e23;
}

.basketCart li {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1;
}

.basketCart li .btn {
  width: auto;
  vertical-align: middle;
  font-size: 16px;
  line-height: 20px;
  padding: 0.6rem;
}

.number_of_items {
  position: absolute;
  top: -10px;
  left: -15px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: hsl(2, 82%, 55%);
  font-size: 14px;
  font-weight: bold;
}

.card-text {
  color: #545454;
  display: flex;
  flex-direction: column;
}

.card-text h6 {
  text-align: center;
}

.basketCart li .btn:first-child {
  color: white;
}

.card-body {
  flex: 0 0 auto;
  padding: 0.3rem;
  margin: auto;
  width: 95%;
}

.basketCart li .btn:hover {
  opacity: 0.7;
}

.basket a {
  padding: 0rem 1rem !important;
  padding-top: 0.2rem !important;
  text-align: center;
  background-color: transparent !important;
}

#church_basket {
  padding: 5px;
}

#church_basket > div > div > div {
  padding: 0.4rem;
  border: 0;
  text-align: left;
}

.innerBasket h6,
.innerBasket button {
  text-align: left;
}

.basket li:hover .basketCart {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background-color: inherit;
}

@media only screen and (max-width: 550px) {
  .basketCart {
    right: 0;
  }
  .checkout_list {
    width: 320px;
  }
}
