.footer {
  margin-right: 20px;

  margin-bottom: 0px;
  box-shadow: 0.03rem 0.04rem 0.1rem rgba(48, 63, 63, 0.3);
  line-height: 1;
  letter-spacing: 5px;
  font-family: "philosopher", sans-serif;
  /* color: antiquewhite; */
  font-size: 14px;
  border-top: 1px solid #efefef;
  border-radius: 0;
  padding: 6px;
  text-align: center;
}
#heart {
  color: #ee2a4a;
  margin: 6px;
  font-size: 20px;
}

.footerContainer {
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}

.footer a {
  text-decoration: none;
  margin: 2px;
  color: inherit;
}
.footer a:hover {
  opacity: 0.8;
}
.copyright-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: center;
  justify-content: center;
  margin: 5px;
}
.copyright-container p {
  margin: 2px;
}
