.items-container {
  position: relative;
  width: 100%;
  height: 0px;
  text-align: center;

  display: flex;
  flex-direction: row;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  overflow: auto;
}
.items-container.show {
  height: 100%;
  border-bottom: 0.5px solid rgba(145, 192, 192, 0.438);
}
.progress {
  width: 100%;
  margin-top: 5px;
  background: #24233185;
  border: 0.1px solid #24233175;
  height: 20px;
  font-size: 14px;
  padding: 0;
}
.progress-bar {
  background: #3ea74e;
  opacity: 0.7;
  font-size: 0.8em;
  text-align: center;
}
.item-box h4,
.item-box > .btn,
.item-box h5,
.item-box p {
  margin-top: 20px;
}
.item-box {
  align-content: center;
}
.summaryTarget:nth-child(even) {
  margin-top: 14px;
}
.partial {
  float: left;
  width: 35%;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding: 5px;
  margin: auto;

  color: #911a1e;
  border: 1px solid #911a1e;
  border-radius: 4px;
}
.title {
  color: #242331;
  font-size: 18px;
  font-weight: 700;
}

.description {
  color: #242331;
  font-size: 16px;
}

.partial h5 {
  text-align: center;
  margin: auto;
}
.darker {
  background: #c8c8c8;
  z-index: 99;
}
.redFlag {
  border-color: red;
  background: rgba(255, 0, 0, 0.296);
}
.item-box > h5 {
  color: rgba(44, 58, 58, 0.719);
  text-shadow: 0rem 0rem 0.2rem rgba(48, 63, 63, 0.3);
}

.addtoBasket:hover {
  opacity: 0.7;
}

.no-margin {
  margin: 0 !important;
}
.no-margin:first-of-type {
  margin-top: 20px !important;
}
.img-container > p {
  font-size: 0.8rem;
  margin: 5px 0;
}

.item-image {
  width: 100%;
  margin: auto;
  box-shadow: 0.1rem 0.1rem 0.2rem rgba(48, 63, 63, 0.3);
}

.quantity {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  padding: 2%;
  align-self: center;
  margin-left: 5.5px;
  margin-top: 15px;
}

.quantity > .currency {
  display: inherit;
  width: 100%;
}
.quantity input[type="number"] {
  -moz-appearance: textfield;
  margin: 0 5%;
  text-align: center;
  box-shadow: inset 0 0 0.1rem rgba(48, 63, 63, 0.3);
  width: inherit;
}

.item-box {
  padding: 20px;
}

.summaryTarget {
  flex-direction: row;
  justify-content: center;
}
.item-box-content {
  justify-content: center;
}
.item-detail {
  flex-direction: column;
  align-items: center;
}
.quantity > button {
  width: 25%;
  /* box-shadow:0 0rem 0.05rem #911a1e; */
  color: #911a1e;
  background: transparent;
  border: 0.05rem solid #911a1e;
}
.quantity > button:hover {
  background: #911a1e60;
}

.bg-dark:hover {
  cursor: pointer;
  background-color: rgba(216, 94, 116, 0.5);
}

@media only screen and (max-width: 550px) {
  .item-box {
    display: block;
    float: left;
  }

  .items-container.show {
    display: flex !important;
    flex-direction: column;
    height: auto;
  }
}
