.main-menu {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.menu-items {
  list-style-type: none;
  padding-inline-start: 0;
  width: 100%;
  display: flex;
  padding-bottom: 0;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
.action {
  display: flex;
  justify-content: center;
  width: 100%;
  border: 0.5px solid #fff;
  padding: 0px;
}

.action:hover {
  opacity: 0.7;
}
.action .btn {
  padding: auto;
  background: transparent;
}
.action.active .btn {
  color: #911a1e;
}

.action .btn:focus {
  box-shadow: none;
}
@media screen and (max-width: 768px) {
  .menu-items {
    flex-direction: column;
  }
}
