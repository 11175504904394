.mainCheckOut {
  align-content: center;
  margin: 5% auto;
  padding: 16px;
  /* box-shadow: 0 0 5px 2px rgba(0,0,0,0.1); */
  border-radius: 4px;
}

.secured-message {
  width: 165px;
  margin: auto;
  background-image: url("../../assets/images/pp_secure.png");
  background-position: center;
  background-size: cover;
  height: 35px;
}

.mainCheckOut h6 {
  padding: 2px !important;
  display: inline;
  margin: 5%;
}

.mainCheckOut .panel {
  margin: auto;
}
.mainCheckOut .btn {
  margin: 16px auto;
  color: #fafafa;
  font-size: 16px;
  font-weight: 500;
}
.secureMSG {
  padding: 16px;
  margin: auto;

  justify-content: center;
  flex-direction: row-reverse;
  align-content: center;
  border: none;
  border-radius: 13px;
  padding: 16px;
}
.secureMSG i {
  width: 30%;
}

.backtoWishlist a {
  text-decoration: none;
  color: #fff;
}
.checkouttable {
  box-shadow: 0.01em 0.01em 0.01em 0.01em #efefef;
  background: #acb4bb55;
  border-radius: 4px;
}

.checkouttable .btn-block {
  box-shadow: none;
  border: none;
  color: #fff;
  border-radius: 4px;
}
