.navbar {
  margin-top: 0;
  padding: 0;
  border: none;

  box-shadow: 0 0rem 0.1rem rgba(48, 63, 63, 0.3);
  /* background-image: url("/assets/images/pattern_5_thumb.png"); */
  z-index: 99;
}

.navbar > .nav > .nav-item {
  padding: 1px;
}
.mr-auto .nav-link {
  border: none;
  font-size: 15px;
}

.collapse .nav-item:hover {
  opacity: 0.8;
}

.navbar-toggler {
  border-radius: 0;
}
