.header {
  position: relative;
  margin: 15px 0;
  border-bottom: none;
  border-radius: 0;
  z-index: 99;
  height: 175px;
  background-image: url("../../assets/images/amc_logo.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
}

.layer {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -50;
}

.top-link {
  display: block;
  width: 100%;
}
