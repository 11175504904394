@import url("https://fonts.googleapis.com/css?family=Philosopher");
@import url("https://fonts.googleapis.com/css?family=Lato");
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: "lato", sans-serif;
  background: url("./assets/images/crossword.png") center fixed;
  color: #545454;
  min-height: 1250px;
  position: relative;
}

.container-fluid {
  padding: 0;
}
.row {
  min-height: 0.05px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.col {
  padding: 0 !important;
}

.primary-color {
  background-color: #424856;
}

.transparent07 {
  opacity: 0.7;
}

.secondary-color {
  background-color: #911a1e;
}

.secondary-text {
  color: #a61e23;
}

.secondary-text:hover {
  color: #a61e23;
}
.primary-text {
  color: #424856;
}

.nav-link.active {
  background-color: #911a1e;
  border: none;
}

.main,
.main > div {
  height: auto;
  margin-bottom: 100px;
  padding: 0;
}

.fadeOut {
  animation: fadeOut ease 10s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
