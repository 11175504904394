.formContainer {
  padding: 16px;
  margin: 5px auto;
  background: rgba(245, 245, 254, 0.5);
  border-radius: 4px;
  min-height: 400px;
}

.formContainer > div.form-group {
  display: flex;
  margin: 25px auto;
  justify-content: flex-start;
}

.formContainer input {
  align-self: flex-end;
  justify-self: flex-end;
}

.formContainer label {
  font-weight: 700;
}

.active-radios {
  display: flex;
  align-items: center;
  padding: 0;
}

.phase-actions {
  margin: 10px 0;
}

.dismiss-alert {
  cursor: pointer;
}

.dismiss-alert:hover {
  opacity: 0.7;
}

.alert-text {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.description-input {
  min-height: 250px;
}
