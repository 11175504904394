.no-data-container {
  margin: auto;
  padding: 20px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-icon {
  font-size: 50px;
  display: block;
  padding: 15px 25px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom: 15px;
}
