.imgContainer {
  background: url(../../assets/images/amc_church_1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px !important;
}

.QouteContainer {
  text-align: center;

  padding: 12px;
  font-family: "philosopher", sans-serif;
}

.card-title {
  font-family: "philosopher", sans-serif;
}
.card {
  padding: 0;
  color: white;
}
.card-img-overlay i h2 {
  text-align: center;
  flex-direction: row;
  padding: 12px;
  font-family: "philosopher", sans-serif;
}
.overlayContent {
  position: absolute;
  right: 10px;
  bottom: 25px;
}
.card-img-overlay a {
  padding: 12px;
  text-align: center;
  color: white;
}

.right-box {
  width: 250px;
  float: right;
}

.card-img-overlay a:hover {
  opacity: 0.8;
}

.card-body {
  padding: 1.75rem;
}
.text-boxes {
  flex-direction: row;
}

.tooltip {
  width: 100px;
  height: 40px;
}
.expandable {
  margin: 2%;
  padding: 23px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;

  box-shadow: 0 0 0.3px #484848;
  transition-property: height;
  transition-duration: 1s;
  transition-delay: 0;
  transition-timing-function: linear;
}
.specialMsg:hover {
  border: 1px solid #484848;
  cursor: pointer;
}
.textContainer {
  padding: 0;
}
.textContainer p {
  line-height: 2.3;
}

@media only screen and (max-width: 550px) {
  .overlayContent {
    top: 125px;
    left: 10px;
  }
}
